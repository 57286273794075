import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const payemntMethodSlice = createSlice({
    name: "payemntMethodSlice",
    initialState: {},
    reducers: {
        resetAddEditDeletePayments(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentMethodsList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getPaymentMethodsList.fulfilled, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    data: action.payload.data,
                    count: action.payload.count,
                };
            })
            .addCase(getPaymentMethodsList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(addPaymentsMethods.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addPaymentsMethods.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addPaymentsMethods.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(editPaymentMethods.pending, (state, action) => {
                return { ...state, editLoading: true };
            })
            .addCase(editPaymentMethods.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editData: action.payload, editSuccess: true };
            })
            .addCase(editPaymentMethods.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    error: action.payload,
                    editSuccess: false,
                };
            });
        builder
            .addCase(deletePaymentMethods.pending, (state, action) => {
                return { ...state, deleteLoading: true };
            })
            .addCase(deletePaymentMethods.fulfilled, (state, action) => {
                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true };
            })
            .addCase(deletePaymentMethods.rejected, (state, action) => {
                return {
                    ...state,
                    deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false,
                };
            });
    },
});

export default payemntMethodSlice.reducer;
export const { resetAddEditDeletePayments } = payemntMethodSlice.actions;

// Thunks
export const getPaymentMethodsList = createAsyncThunk("paymentMethods/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getPaymentMethodsList
            // + `/?pageNumber=${body.pageNumber}&text=${body.text}`
        );
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const addPaymentsMethods = createAsyncThunk("paymentMethods/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addPaymentsMethods, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const editPaymentMethods = createAsyncThunk("paymentMethods/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editPaymentMethods+`?gatewayId=${body.id}`, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const deletePaymentMethods = createAsyncThunk("paymentMethods/delete", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deletePaymentMethods + `?gatewayId=${body}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
