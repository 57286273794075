import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { addPaymentsMethods, editPaymentMethods, resetAddEditDeletePayments } from "../../../../../redux/slices/payment_methods_slice";

export default function AddEditPaymentDialog({ onHide, editCode, permissions, getPaymentMethodsList }) {
    const { addSuccess, addLoading, addError, editLoading, editError, editSuccess } = useSelector((state) => state.paymentMethods);

    const dispatch = useDispatch();
    const [paymentMethodImage, setPayemntMethodIamge] = useState();
    const [newImageSelected, setNewImageSelected] = useState(false); // Tracks if a new image has been chosen

    const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            status: true,
            icon: "",
            secret: "",
            clientId: "",
            apiKey: "",
            testClientId: "",
            testApiKey: "",
            testSecret: ""
        },
        onSubmit: async (data) => {

            const payload = {
                name: data.name,
                status: data.status,
                icon: data.icon,
                secret: data.secret,
                clientId: data.clientId,
                apiKey: data.apiKey,
                testClientId: data.testClientId,
                testApiKey: data.testApiKey,
                testSecret: data.testSecret
            }

            if (!editCode) {
                // Validate the image format only if a new image is selected or if editing requires a valid image
                if ((!editCode || newImageSelected) && (!paymentMethodImage || !paymentMethodImage.startsWith("data:image"))) {
                    toast.error("Please upload a valid image file before submitting.");
                    return;
                }
                dispatch(addPaymentsMethods(payload));
            } else {
                payload.id = editCode._id;

                if (newImageSelected && (!paymentMethodImage || !paymentMethodImage.startsWith("data:image"))) {
                    toast.error("Please upload a valid image file before submitting.");
                    return;
                }
                dispatch(editPaymentMethods(payload));
            }
            dispatch(getPaymentMethodsList(
                // { pageNumber: 0, text: "" }
            ));
        },
    });

    useEffect(() => {
        if (addSuccess) {
            toast.success("Payment successfully added");
            onHide();
            dispatch(getPaymentMethodsList());
        } else if (addError) {
            toast.error(addError);
        }

        if (editSuccess) {
            toast.success("Payments successfully updated");
            onHide();
            dispatch(getPaymentMethodsList());
        } else if (editError) {
            toast.error(editError);
        }

        return () => {
            dispatch(resetAddEditDeletePayments());
        };
    }, [addSuccess, addError, editSuccess, editError]);


    useEffect(() => {
        if (editCode) {
            loadInitialValues();
            setNewImageSelected(false);
            setPayemntMethodIamge(editCode.image);
        }
    }, [editCode]);

    useEffect(() => {
        if (editCode !== null) {
            loadInitialValues();
        }
    }, []);
    const loadInitialValues = () => {
        formik.setFieldValue("name", editCode.name);
        formik.setFieldValue("icon", editCode.icon);
        formik.setFieldValue("secret", editCode.secret);
        formik.setFieldValue("clientId", editCode.clientId);
        formik.setFieldValue("apiKey", editCode.apiKey);
        formik.setFieldValue("testApiKey", editCode.testApiKey);
        formik.setFieldValue("testSecret", editCode.testSecret);
        formik.setFieldValue("testClientId", editCode.testClientId);
        formik.setFieldValue("status", editCode.status);
    };

    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Check if file exists and is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPayemntMethodIamge(e.target.result);
                setNewImageSelected(true);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Please upload a valid image file.");
            setNewImageSelected(true);
            setPayemntMethodIamge(null);
        }
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="name" label="Name" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <label style={{ marginLeft: "25px", marginBottom: "10px", display: "flex" }}>Image</label>
                    <InputText style={{ width: "90%", marginLeft: "24px" }} id="icon" placeholder="Upload Payment Method Image" name="icon" type="file" formik={formik} accept="image/*" onChange={handleFileChange} />
                </div>
                {editCode != null && (
                    <div className="col-12 md:col-6 p-fluid">
                        <CustomInputField type="dropdown" iden="status" name="status" options={statusList} formik={formik} optionLabel="name" optionValue="code" placeHolder="Please Select" />
                    </div>
                )}

                <div className="ml-4 col-11">
                    <h5>Production</h5>
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="secret" label="Secret Key" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="clientId" label="Client Id" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="apiKey" label="API Key" formik={formik} type="text" />
                </div>
                <div className="ml-4 col-11">
                    <h5>Test</h5>
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="testSecret" label="Secret Key" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="testClientId" label="Client Id" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="testApiKey" label="API Key" formik={formik} type="text" />
                </div>
                <div className="col-12 p-fluid">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
            </div>
        </form>
    );
}
