import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import VideoPlayer from "../components/VideoPlayer";
import Sidebar from "../components/Sidebar";
import Notifications from "../components/Notifications";
import { SocketContext } from "../../../../../Context";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Peer from "simple-peer";

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderRadius: 15,
        margin: "30px 100px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "600px",
        border: "2px solid black",

        [theme.breakpoints.down("xs")]: {
            width: "90%",
        },
    },
    image: {
        marginLeft: "15px",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    },
}));
const iceServers = [{ urls: 'stun:iphone-stun.strato-iphone.de:3478' }, { urls: 'stun:openrelay.metered.ca:80' }, { urls: 'turn:openrelay.metered.ca:80', username: 'openrelayproject', credential: 'openrelayproject', }, { urls: 'turn:openrelay.metered.ca:443', username: 'openrelayproject', credential: 'openrelayproject', }, { urls: 'turn:openrelay.metered.ca:443?transport=tcp', username: 'openrelayproject', credential: 'openrelayproject', },];
const VideoCall = ({ userId, callerName, userToCall }) => {
    const classes = useStyles();
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [stream, setStream] = useState();
    const [name, setName] = useState(callerName);
    const [call, setCall] = useState({});
    const [callType, setCallType] = useState("audio");
    const me = "admin";
    const loggedInUser = localStorage.getItem("userId")
    const myVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();
    const { socket } = useContext(SocketContext);
    // const resetVideoRefs = () => {
    //     userVideo.current.srcObject = null;
    // };
    const resetVideoRefs = () => {
        if (userVideo.current) userVideo.current.srcObject = null;
    };
    useEffect(() => {
        const constraints = callType === "audio" ? { audio: true } : { video: true, audio: true };
        navigator.mediaDevices.getUserMedia(constraints).then((currentStream) => {
            setStream(currentStream);
            // myVideo.current.srcObject = currentStream;
            if (callType === "video" && myVideo.current) {
                myVideo.current.srcObject = currentStream; // Attach stream to video if video call
            }
        });
        // socket.on("callUser", ({ from, name: callerName, signal }) => {
        //     setCall({ isReceivingCall: true, from, name: callerName, signal });
        // });
        socket.on("dropCall", () => {
            setCall({});
            setCallEnded(true);
            connectionRef.current.destroy();
            window.location.reload();
        });
    }, [callType]); //Re-run whenever callType changes

    const answerCall = () => {
        setCallAccepted(true);

        const peer = new Peer({ initiator: false, trickle: false, stream, config: { iceServers } });

        peer.on("signal", (data) => {
            socket.emit("answerCall", { signal: data, to: call.from });
        });

        peer.on("stream", (currentStream) => {
            if (userVideo.current) userVideo.current.srcObject = currentStream;
        });

        peer.signal(call.signal);

        connectionRef.current = peer;
    };

    const callUser = (id) => {
        const peer = new Peer({ initiator: true, trickle: false, stream, config: { iceServers } });

        peer.on("signal", (data) => {
            socket.emit("callUser", {
                userToCall: id,
                signalData: data,
                from: me,
                name,
                callerId: loggedInUser,
                callType, 
            });
        });

        peer.on("stream", (currentStream) => {
            userVideo.current.srcObject = currentStream;
        });

        socket.on("callAccepted", (signal) => {
            setCallAccepted(true);
            peer.signal(signal);
        });

        connectionRef.current = peer;
    };

    const leaveCall = () => {
        setCall({});
        setCallEnded(true);
        resetVideoRefs();
        connectionRef.current.destroy();
        socket.emit("dropCall", { ...call, userToCall: userId });
        window.location.reload();
    };

    return (
        <div className={classes.wrapper}>
            <div style={{ marginBottom: "20px" }}>
                <label>
                    <input
                        type="radio"
                        name="callType"
                        value="audio"
                        checked={callType === "audio"}
                        onChange={() => setCallType("audio")}
                    />
                    Audio Only
                </label>
                <label style={{ marginLeft: "20px" }}>
                    <input
                        type="radio"
                        name="callType"
                        value="video"
                        checked={callType === "video"}
                        onChange={() => setCallType("video")}
                    />
                    Audio + Video
                </label>
            </div>
            {callType === "video" && (
                <VideoPlayer data={{ callerName, userId, call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser, leaveCall, answerCall, userToCall }} />
            )}
            <Sidebar data={{ userId, call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser, leaveCall, answerCall }}>
                <Notifications data={{ userId, call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser, leaveCall, answerCall }} />
            </Sidebar>
        </div>
    );
};

export default VideoCall;
