import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "primereact/editor";
import { getFaqsCategoryList } from "../../../../../redux/slices/faqs_category_slice";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_filed";
import { addFaq, editFaq, getFaqsList, resetAddEditFaq } from "../../../../../redux/slices/faqs_slice";

export default function AddEditFaqs({ editData, onHide, category, permissions }) {
    const dispatch = useDispatch();

    const { data: list } = useSelector((state) => state.faqCategory);
    const { addLoading, addError, editError, editLoading, addSuccess, editSuccess } = useSelector((state) => state.faqsList);
    const [answer, setAnswer] = useState("");

    const validationSchema = Yup.object().shape({
        category: Yup.string().required("FAQ category is required"),
        question: Yup.string().required("Question is required"),
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("FAQ successfully added");
                onHide();
                dispatch(getFaqsList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("FAQ successfully updated");
                onHide();
                dispatch(getFaqsList());
            } else {
                toast.error(editError);
            }
        }

        return () => {
            dispatch(resetAddEditFaq());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            question: "",
            category: "",
            isActive: "",
        },

        onSubmit: async (data) => {
            if (answer.length === 0) {
                toast.warn("Answer cannot be empty");
                return;
            }
            data["answer"] = answer;
            if (editData === null) {
                dispatch(addFaq(data));
            } else {
                data["FAQId"] = editData._id;
                data["answer"] = answer;

                dispatch(editFaq(data));
            }
        },
    });
    useEffect(() => {
        if (editData !== null) {
            loadValues();
        }
        dispatch(getFaqsCategoryList());
    }, []);

    const loadValues = () => {
        formik.setFieldValue("category", category);
        formik.setFieldValue("question", editData.question);
        formik.setFieldValue("isActive", editData.isActive);
        setAnswer(editData.answer);
    };
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className=" p-fluid grid">
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="category" formik={formik} options={list} type="dropdown" optionLabel="name" optionValue="_id" placeholder="Select a category" />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomInputField iden="question" formik={formik} />
                        </div>
                        {editData != null && (
                            <div className="col-12 md:col-6">
                                {/* <div className="field"> */}
                                <CustomInputField label="Status" iden={"isActive"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                                {/* </div> */}
                            </div>
                        )}
                        <div className="col-12">
                            <Editor style={{ height: "150px" }} name="answer" value={answer} onTextChange={(e) => setAnswer(e.htmlValue)} />
                        </div>
                        {/* <div className="col-12">
                            <div className="button_class-modal">{permissions?.add && editData == null && <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                            <div className="button_class-modal">{permissions?.edit && editData && <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                        </div> */}
                        {/* <div className="col-12">
                            <div className="button_class-modal">{permissions?.add && editData == null && <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
<<<<<<< HEAD
                            <div className="button_class-modal">{ <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
=======
                            <div className="button_class-modal">{<Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
>>>>>>> e3b10f38dce3a6d769e0bd1053321978384e339f
                        </div> */}
                        <div className="col-12">
                            <div className="button_class-modal">
                                {((permissions?.add && !editData) || (permissions?.edit && editData)) && (
                                    <Button
                                        loading={editData ? editLoading : addLoading}
                                        type="submit"
                                        label={editData ? "Update" : "Add"}
                                        className="p-button-success btn__default_modal p-button-rounded"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
