import React, { useState, useEffect, useRef, createContext, useContext } from "react";
import "./App.scss";

import classNames from "classnames";
import { Route, useLocation, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { ToastContainer, toast } from "react-toastify";
import { AppConfig } from "./AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useSelector } from "react-redux";
import LoginScreen from "./app/features/screens/auth/pages/login_screen";
import DriversScreen from "./app/features/screens/drivers/pages/drivers_screen";
import UsersScreen from "./app/features/screens/users/pages/users_screen";
import UserRideScreen from "./app/features/screens/rides/pages/user_ride_screen";
import DriverRideDetailsScreen from "./app/features/screens/rides/pages/driver_ride_details_screen";
import PassengerRideDetailsScreen from "./app/features/screens/rides/pages/passenger_ride_details_screen";
import dashboard from "./app/features/screens/dashboard/pages/dashboard";
import dashboardDuplicate from "./app/features/screens/dashboard/pages/dashboard_screen";
import AllRidesScreen from "./app/features/screens/rides/pages/all_rides_screen";
import { Switch, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AdminUsersScreen from "./app/features/screens/users/pages/admin_users_screen";
import FareManagementScreen from "./app/features/screens/fare/pages/fare_management_screen";
import DocumentsVerificationsScreen from "./app/features/screens/drivers/pages/documents_verifications_screen";
import AdsScreen from "./app/features/screens/ads/pages/ads_screen";
import CorporateCodeScreen from "./app/features/screens/corporate_codes/pages/corporate_codes_screen";
//import { icon } from '@fortawesome/fontawesome-svg-core';
import AvtiveSupportRequestsScreen from "./app/features/screens/Support/components/Avtive_Support";
import ResolvedSupportRequestsScreen from "./app/features/screens/Support/components/Resolved_Support";
import Support_chat from "./app/features/screens/Support/pages/Support_chat";
import VersionControlScreen from "./app/features/screens/versions/pages/version_control_screen";
import FaqsScreen from "./app/features/screens/faqs/pages/faqs_screen";
// import appUrl from "./app/features/constants/appUrl";
// import dotenv from "dotenv";
import { vehicleType } from "./app/features/screens/vehiclemanagement/pages/vehicle-type";
import { vehicleModel } from "./app/features/screens/vehiclemanagement/pages/vehicle-model";
import { vehicleMakers } from "./app/features/screens/vehiclemanagement/pages/vehicle-makers";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "react-toastify/dist/ReactToastify.css";

import NotificationScreen from "./app/features/screens/notification/pages/notification_screen";
import SubjectScreen from "./app/features/screens/Support/pages/subject/Subject";
import encryptData from "./app/features/services/encryptData";
import decryptData from "./app/features/services/decryptData";
import VehiclesScreen from "./app/features/screens/verification/pages/vehicles_screen";
import PersonalScreen from "./app/features/screens/verification/pages/personal_screen";
import TutorailScreen from "./app/features/screens/tutorials/pages/tutorail_screen";
import Profile from "./app/features/screens/profile/Profile";
import PaidAccountsScreen from "./app/features/screens/accounts/pages/paid_accounts_screen";
import UnPaidAccountsScreen from "./app/features/screens/accounts/pages/unpaid_account_screen";
import InprogressScreen from "./app/features/screens/accounts/pages/inprogress_account_screen";
import Roles from "./app/features/screens/rolesmanagement/pages/roles";
import Module from "./app/features/screens/rolesmanagement/pages/module";
import UserRatingScreen from "./app/features/screens/rides/pages/user_rating_screen";
import UserVehiclesListScreen from "./app/features/screens/drivers/components/vehicles_user";
import ReferrerScreen from "./app/features/screens/referrer/pages/referrer_screen";
import SmsLogs from "./app/features/screens/smsLogs/pages/sms_logs_screen";
import BlockedUsers from "./app/features/screens/users/pages/blocked_users_screen";
import ResetPasswordScreen from "./app/features/screens/auth/pages/reset_password_screen";
// import { SocketContext } from "./SocketProvider";
import socketsPoint from "./app/features/constants/socketsPoints";
import routesList from "./app/features/constants/routes";

import AllUsersScreen from "./app/features/screens/users/pages/all_users_screen";
import OwnerRideScreen from "./app/features/screens/rides/pages/owner_rides_screen";
import DriverRatingScreen from "./app/features/screens/rides/pages/driver_rating_screen";
import NotFound from "./app/features/components/not_found_screen";
import videoCall from "./app/features/screens/videoCall/pages";
import { SocketContext } from "./Context";
const axios = require("axios");

// import { SocketContext } from "./SocketProvider";
// import React, { useContext } from "react";
// import socket from "./socket";

// export const SocketContext = createContext(null);
const App = () => {
    const history = useHistory();

    // const { socket, connected } = useContext(SocketContext);
    const [menu, setMenu] = useState([]);
    const [paths, setPaths] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [vehicleCount, setVehicleCount] = useState("");
    const [unverifiedDriversCount, setUnverifiedDriversCount] = useState("");
    const [unrepliedMessageCount, setUnrepliedMessageCount] = useState("");

    const copyTooltipRef = useRef();
    const location = useLocation();
    const { socket } = useContext(SocketContext);
    const loginUser = useSelector((state) => state.loginUser);
    const { user } = loginUser;

    useEffect(() => {
        setTimeout(() => {
            socket.on("newTicket", (parseData) => {
                let data = JSON.parse(parseData);
                data = decryptData(data?.cipher);
                if (!data?.corporate) {
                    toast.info("New Ticket Generated!");
                }
            });
            socket.on("newSupportMessage", (response) => {
                response = decryptData(response?.cipher);
                toast.info("New Support Message!");
                //check
                // if (!response?.corporate) {
                //     toast.info("New Support Message!");
                // }
            });
            socket.on(socketsPoint.newVehicleListener, (data) => {
                setVehicleCount(data.count);
            });

            socket.on(socketsPoint.sideBarUpdate, (data) => {
                if (user?.role?._id !== "6374d6149e13da1d01f97b06") {
                    const { menu, role } = data;
                    const updatedUser = { ...user }; // Create a new object by spreading the existing user object
                    updatedUser.menu = menu;
                    if (user) localStorage.setItem("user", JSON.stringify(updatedUser));

                    if (role?._id == user?.role?._id) {
                        const updatedMenu = menu?.map((item) => {
                            let items = item.items;
                            if (item?.label == "verifications") {
                                items = item?.items?.map((sub) => {
                                    if (sub.label == "vehicles") {
                                        return { ...sub, badge: vehicleCount ? vehicleCount : "", label: capitalizeFirstLetter(sub.label) };
                                    }
                                    return { ...sub, label: capitalizeFirstLetter(sub.label) };
                                });
                            } else {
                                items = item?.items?.map((sub) => {
                                    return { ...sub, label: capitalizeFirstLetter(sub.label) };
                                });
                            }
                            return { ...item, items };
                        });
                        setMenu(updatedMenu);
                    }
                }
            });
            socket.on(socketsPoint.permissionsUpdatedListener, (data) => {
                if (user.role._id !== "6374d6149e13da1d01f97b06") {
                    const { permissions, roleId } = data;
                    const updatedUser = { ...user };
                    if (user.role._id == roleId) {
                        updatedUser.permissions = permissions;
                        localStorage.setItem("user", JSON.stringify(updatedUser));
                        window.location.reload();
                    }
                }
            });
            socket.emit(socketsPoint.getSideBarEmitter, { roleId: user?.role?._id });
        }, 3000);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            socket.emit(socketsPoint.getPendingVehicleCountEmitter, {}, (data) => {
                setVehicleCount(data?.count);
            });
            socket.emit(socketsPoint.getUnverifiedDriversCountEmitter, {}, (data) => {
                setUnverifiedDriversCount(data?.count);
            });
            socket.emit(socketsPoint.getUnrepliedMessageCountEmitter, {}, (data) => {
                setUnrepliedMessageCount(data?.count);
            });
        });
    }, [routes]);
    useEffect(() => {
        const filteredRoutes = routesList.filter((route) => {
            return paths.some((path) => {
                if (path == "/") {
                    return route.path == path;
                }
                return route.path.startsWith(path);
            });
        });
        for (const route of filteredRoutes) {
            const matchingPermission = user?.permissions?.find((permission) => permission.path === route.path);
            
            // const matchingPermission = user?.permissions?.find((permission) => route.path.startsWith(permission.path));
            if (user?.role?.role == "Super_Admin") {
                route.permissions = { add: true, edit: true, view: true, delete: true };
            } else if (matchingPermission) {
                route.permissions = matchingPermission.permissions;
            } else {
                route.permission = { add: false, edit: false, view: false, delete: false };
            }
        }
        setRoutes(filteredRoutes);
        // if (paths.length > 0 && paths.find((path) => path == location.pathname) == undefined) history.push(paths[0]);
        // setRoutes(routesList);
    }, [paths, location.pathname]);

    useEffect(() => {
        const modulesPaths = menu?.flatMap((item) => item?.items?.map((nestedItem) => nestedItem?.to));
        setPaths(modulesPaths);
    }, [menu]);
    // const socket = io(appUrl.baseUrl+'?name=Waseem 3');
    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };
    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };
    function capitalizeFirstLetter(str) {
        if (str?.length === 0) {
            return str; // Return empty string if input is empty
        } else {
            return str?.charAt(0).toUpperCase() + str?.slice(1);
        }
    }
    useEffect(() => {
        if (user) {
            const updatedMenu = user?.menu?.map((item) => {
                let items = item?.items;
                if (item?.label == "verifications") {
                    items = item?.items?.map((sub) => {
                        if (sub.label == "vehicles") {
                            return { ...sub, badge: vehicleCount ? vehicleCount : "", label: capitalizeFirstLetter(sub?.label) };
                        } else if (sub.label == "personal") {
                            return { ...sub, badge: unverifiedDriversCount ? unverifiedDriversCount : "", label: capitalizeFirstLetter(sub?.label) };
                        }
                        return { ...sub, label: capitalizeFirstLetter(sub.label) };
                    });
                } else {
                    items = item?.items?.map((sub) => {
                        return { ...sub, label: capitalizeFirstLetter(sub.label) };
                    });
                }
                return { ...item, items };
            });
            setMenu(updatedMenu);
        }
    }, [user, vehicleCount]);

    useEffect(() => {
        if (user) {
            const updatedMenu = user?.menu?.map((item) => {
                let items = item?.items;
                if (item?.label === "support") {
                    items = item?.items?.map((sub) => {
                        if (sub.label === "open" ) { //|| sub.label === "resolved"
                            return {
                                ...sub,
                                badge: unrepliedMessageCount ? unrepliedMessageCount : sub.badge || "", // Preserve existing badge if count is 0
                                label: capitalizeFirstLetter(sub?.label),
                            };
                        }
                        return { ...sub, label: capitalizeFirstLetter(sub.label) };
                    });
                } else {
                    items = item?.items?.map((sub) => {
                        return { ...sub, label: capitalizeFirstLetter(sub.label) };
                    });
                }
                return { ...item, items };
            });
            setMenu(updatedMenu);
        }
    }, [user, unrepliedMessageCount]);
    

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <>
            <ToastContainer />
            {user === undefined ? (
                // <Route path="/" component={LoginScreen} />
                <div>
                    <Switch>
                        <Route exact path="/" render={() => <LoginScreen />} />
                        <Route exact path="/resetpassword" render={() => <ResetPasswordScreen />} />
                        <Redirect to="/" />
                    </Switch>
                </div>
            ) : (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar
                        onToggleMenuClick={onToggleMenuClick}
                        layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive}
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                        vehicleCount={vehicleCount} // Pass the vehicleCount
                        unverifiedDriversCount={unverifiedDriversCount} // Pass the unverifiedDriversCount
                    />
                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>
                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Switch>
                                {routes.map((route) => {
                                    return <Route path={route.path} render={(props) => <route.component permissions={route.permissions} {...props} {...route.props} />} exact />;
                                })}
                                <Route path="/videoCall" component={videoCall} />
                                {/* <Route path="/api/configuration" component={configurationsScreen} /> */}
                                <Route path="/api/profile" component={Profile} />
                                <Route exact path="/resetpassword" render={() => <ResetPasswordScreen />} />
                                <Route path="" component={NotFound} />
                            </Switch>
                        </div>
                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default App;
