import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const configurationSlice = createSlice({
    name: "configuration",
    initialState: {},
    reducers: {
        resetUpdateConfigurations(state, action) {
            return { ...state, updateSuccess: undefined};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigurations.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getConfigurations.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload,
                    success:true 
                };
            })
            .addCase(getConfigurations.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    success:false
                };
            });

        builder
            .addCase(updateConfigurations.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateConfigurations.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateConfigurations.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });
    },
});

export default configurationSlice.reducer;
export const { resetUpdateConfigurations } = configurationSlice.actions;

// Thunks
export const getConfigurations = createAsyncThunk("configuration/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getConfig);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const updateConfigurations = createAsyncThunk("configuration/update", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.updateConfig, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
