// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addCorporateCode, editCorporateCode, getCorporateCodesList, resetAddEditCorporateCode, getGoogleAddress } from "../../../../../redux/slices/corporate_code_slice";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import CustomInputField from "../../../components/custom_input_filed";
// import { Button } from "primereact/button";
// import { toast } from "react-toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
// import { Dropdown } from "primereact/dropdown";
// import { axiosApi } from "../../../constants/axios_api";
// import appUrl from "../../../constants/appUrl";
// import { InputText } from "primereact/inputtext";

// export default function AddCorporateCodeDialog({ onHide, editCode, permissions, tableData, getCorporateCodesList }) {
//     //redux
//     const corporateCodeReducer = useSelector((state) => state.corporateCode);
//     const modifiedLocations = corporateCodeReducer?.address?.map((location) => ({
//         title: location,
//     }));
//     const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateCodeReducer;
//     const [selectedAddress, setSelectedAddress] = useState("");
//     const [corporateLov, setCorporateLov] = useState();
//     const [loading, setLoading] = useState(false);
//     const [loadingIcon, setLoadingIcon] = useState("");
//     // const [corporateList , setCorporateList]= useState()
//     const dispatch = useDispatch();

//     const statusList = [
//         {
//             code: true,
//             name: "Active",
//         },
//         {
//             code: false,
//             name: "InActive",
//         },
//     ];

//     const validationSchema = Yup.object().shape({
//         corporateId: Yup.string().required("This field is required."),
//         code: Yup.string().required("This field is required.").min(11, "Please enter valid code xxxx-xxxxxx").max(11, "Please enter valid code xxxx-xxxxxx"),
//         title: Yup.string().required("This field is required"),
//         currency: Yup.string().required("This field is required"),
//         fee: Yup.number().typeError("Please enter a valid number").min(-100, "Number must be greater than or equal to -100").max(100, "Number must be less than or equal to 100").required("This field is required."),
//     });

//     const formik = useFormik({
//         validationSchema: validationSchema,
//         initialValues: {
//             corporateId: "",
//             code: "",
//             title: "",
//             fee: "",
//             currency: "",
//         },
//         onSubmit: async (data) => {
//             const obj = {
//                 code: data?.code,
//                 fee: data?.fee,
//                 corporateId: data?.corporateId,
//                 title: data?.title,
//                 currency: data?.currency,
//             };
//             if (editCode == null) {
//                 const existingCodes = corporateCodeReducer?.data?.flatMap(item =>
//                     item.coporateCodes.map(code => ({
//                         corporateId: item.corporteData._id,
//                         code: code.code
//                     }))
//                 ) || []; // Gather all existing corporate codes
//                 // Check for duplicate codes for the selected corporate ID
//                 const isDuplicate = existingCodes.some((code) =>
//                     code.code === data.code && code.corporateId === data.corporateId
//                 );
//                 if (isDuplicate) {
//                     toast.error("This code already exists for the selected corporate.");
//                     return; // Stop further execution
//                 }
//                 dispatch(addCorporateCode(obj));
//                 await dispatch(
//                     getCorporateCodesList({
//                         pageNumber: 0,
//                         text: "",
//                         perPageRecords: 50,
//                     })
//                 );
//             } else {
//                 // Checking for duplicates when updating
//                 const existingCodes = corporateCodeReducer?.data?.flatMap(item =>
//                     item.coporateCodes.map(code => ({
//                         corporateId: item.corporteData._id,
//                         code: code.code
//                     }))
//                 ) || [];
//                 const isDuplicate = existingCodes.some((code) =>
//                     code.code === data.code && code.corporateId === data.corporateId && code.code !== editCode.data.code
//                 );

//                 if (isDuplicate) {
//                     toast.error("This code already exists for the selected corporate.");
//                     return;
//                 }
//                 setLoading(true);
//                 setLoadingIcon("pi pi-spinner");
//                 const editObj = {
//                     code: data?.code,
//                     fee: data?.fee,
//                     corporateId: data?.corporateId,
//                     title: data?.title,
//                     active: data?.active,
//                     currency: data?.currency,
//                 };
//                 try {
//                     const response = await axiosApi.patch(`${appUrl?.baseUrl}${appUrl.editCorporateCode}?id=${editCode?.key}`, editObj);
//                     if (response?.data?.statusCode === 200) {
//                         setLoading(false);
//                         setLoadingIcon("");
//                         onHide();
//                         toast.success("Corporate Code updated");
//                         await dispatch(
//                             getCorporateCodesList({
//                                 pageNumber: 0,
//                                 text: "",
//                                 perPageRecords: 50,
//                             })
//                         );

//                     }

//                 } catch (error) {
//                     toast.error(error);
//                 }
//             }
//         },
//     });

//     useEffect(() => {
//         if (addSuccess !== undefined) {
//             if (addSuccess === true) {
//                 toast.success("Corporate Code successfully added");
//                 onHide();
//                 dispatch(getCorporateCodesList());
//             } else {
//                 toast.error(addError);
//             }
//         }
//         if (editSuccess !== undefined) {
//             if (editSuccess === true) {
//                 toast.success("Corporate Code successfully updated");
//                 onHide();
//                 dispatch(getCorporateCodesList());
//             } else {
//                 toast.error(editError);
//             }
//         }
//         return () => {
//             dispatch(resetAddEditCorporateCode());
//         };
//     }, [addSuccess, addError, editSuccess, editError]);
//     useEffect(() => {
//         if (addSuccess !== undefined) {
//             if (addSuccess === true) {
//                 toast.success("Corporate Code successfully added");
//                 onHide();
//                 dispatch(getCorporateCodesList());
//             } else {
//                 toast.error(addError);
//             }
//         }
//         if (editSuccess !== undefined) {
//             if (editSuccess === true) {
//                 toast.success("Corporate Code successfully updated");
//                 onHide();
//                 dispatch(getCorporateCodesList());
//             } else {
//                 toast.error(editError);
//             }
//         }
//         return () => {
//             dispatch(resetAddEditCorporateCode());
//         };
//     }, [addSuccess, addError, editSuccess, editError]);

//     useEffect(() => {
//         if (editCode !== null) {
//             loadInitialValues();
//         }
//     }, [tableData, editCode]);

//     const loadInitialValues = () => {
//         formik.setFieldValue("corporateId", editCode?.data?.corporate);
//         formik.setFieldValue("title", editCode?.data?.title);
//         formik.setFieldValue("code", editCode?.data?.code);
//         formik.setFieldValue("fee", editCode?.data?.fee);
//         formik.setFieldValue("active", editCode?.data?.active);
//         formik.setFieldValue("currency", editCode?.data?.currencyId);
//     };
//     const [searchText, setSearchText] = useState("");

//     const handleInputChange = (event) => {
//         const text = event;

//         setSelectedAddress(event);
//         // Update the search text
//         setSearchText(text);

//         // dispatch(getGoogleAddress({ text }));
//     };
//     const handleOnSelect = (e) => {
//         setSelectedAddress(e.title);
//     };

//     // const corporateList = tableData?.map((item) => {
//         return {
//             name: item?.data?.name,
//             value: item?.key,
//         };
//     });
//     const handleDropdownChange = (e) => {
//         formik.setFieldValue("name", e.target.value);
//     };

//     const [currecnyCode, setCurrencyCode] = useState();

//     const getCurrencyCode = async () => {
//         try {
//             const response = await axiosApi.get(`${appUrl?.baseUrl}api/currency/`);
//             setCurrencyCode(response?.data?.data);
//         } catch (error) {
//             toast.error("Can not get currecny code");
//         }
//     };

//     useEffect(() => {
//         getCurrencyCode();
//     }, []);

//     return (
//         <form onSubmit={formik.handleSubmit}>
//             <div className="grid">
//                 <div className="p-fluid col-12 md:col-6">
//                     {/* <CustomInputField iden="corporateId" formik={formik} type="dropdown" label="Corporate" placeHolder="Please select corporate" options={corporateList} optionLabel="name" optionValue="value" /> */}
//                 </div>
//                 <div className="p-fluid col-12 md:col-6" >
//                     {editCode !== null ? <CustomInputField iden="code" formik={formik} placeHolder="xxxx-xxxxxx" type="text" disabled={true} />
//                         : <CustomInputField iden="code" formik={formik} mask="****-******" placeHolder="xxxx-xxxxxx" type="text" />}
//                 </div>
//                 <div className="p-fluid col-12 md:col-6 testField">

//                     <CustomInputField type="text" formik={formik} iden="title"  placeHolder="Please enter title"  />
//                 </div>
//                 <div className="p-fluid col-12 md:col-6">
//                     <CustomInputField iden="fee" formik={formik} type="number" />
//                 </div>
//                 <div className="p-fluid col-12 md:col-6">
//                     <CustomInputField iden="currency" formik={formik} type="dropdown" label="Currency Code" placeHolder="Please select currency code" options={currecnyCode} optionLabel="currencyCode" optionValue="_id" />
//                 </div>

//                 {editCode != null && (
//                     <div className="p-fluid col-12 md:col-6">
//                         <div className="field">
//                             <CustomInputField className="col-12" iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
//                         </div>
//                     </div>
//                 )}
//                 <div className="p-fluid col-12">
//                     <div className="button_class-modal">
//                         {/* {permissions?.edit && editCode !== null && ( */}
//                         <Button type="submit" label={editCode === null ? "Add" : "Update"} disabled={loading ? true : false} icon={loadingIcon} iconPos="left" className="p-button-success btn__default_modal p-button-rounded" />
//                         {/* )} */}
//                     </div>
//                     <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
//                 </div>
//             </div>
//         </form>
//     );
// }

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCorporateCode, editCorporateCode, getCorporateCodesList, resetAddEditCorporateCode } from "../../../../../redux/slices/corporate_code_slice";
import { getCorporateList, getGoogleAddress } from "../../../../../redux/slices/AddCorporateSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Dropdown } from "primereact/dropdown";
import { axiosApi } from "../../../constants/axios_api";
import appUrl from "../../../constants/appUrl";
import { InputText } from "primereact/inputtext";

export default function AddCorporateCodeDialog({ onHide, editCode, permissions, tableData, getCorporateCodesList }) {
    //redux
    const corporateCodeReducer = useSelector((state) => state.corporateCode);
    const modifiedLocations = corporateCodeReducer?.address?.map((location) => ({
        title: location,
    }));
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateCodeReducer;

    const {data:corporateList } = useSelector((state) => state.corporates);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [corporateLov, setCorporateLov] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingIcon, setLoadingIcon] = useState("");
    const dispatch = useDispatch();

    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];

    const validationSchema = Yup.object().shape({
        corporateId: Yup.string().required("This field is required."),
        code: Yup.string().required("This field is required.").min(11, "Please enter valid code xxxx-xxxxxx").max(11, "Please enter valid code xxxx-xxxxxx"),
        title: Yup.string().required("This field is required"),
        currency: Yup.string().required("This field is required"),
        fee: Yup.number().typeError("Please enter a valid number").min(-100, "Number must be greater than or equal to -100").max(100, "Number must be less than or equal to 100").required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            corporateId: "",
            code: "",
            title: "",
            fee: "",
            currency: "",
        },
        onSubmit: async (data) => {
            const obj = {
                code: data?.code,
                fee: data?.fee,
                corporateId: data?.corporateId,
                title: data?.title,
                currency: data?.currency,
            };
            if (editCode == null) {
                // const existingCodes = corporateCodeReducer?.data?.flatMap(item =>
                //     item?.coporateCodes?.map(code => ({
                //         corporateId: item.corporteData._id,
                //         code: code.code
                //     }))
                // ) || []; // Gather all existing corporate codes
                // // Check for duplicate codes for the selected corporate ID
                // const isDuplicate = existingCodes.some((code) =>
                //     code.code === data.code && code.corporateId === data.corporateId
                // );
                // if (isDuplicate) {
                //     toast.error("This code already exists for the selected corporate.");
                //     return; // Stop further execution
                // }
                dispatch(addCorporateCode(obj));
                await dispatch(
                    getCorporateCodesList({
                        pageNumber: 0,
                        text: "",
                        perPageRecords: 50,
                    })
                );
            } else {
                // Checking for duplicates when updating
                const existingCodes = corporateCodeReducer?.data?.flatMap(item =>
                    item.coporateCodes.map(code => ({
                        corporateId: item.corporteData._id,
                        code: code.code
                    }))
                ) || [];
                const isDuplicate = existingCodes.some((code) =>
                    code.code === data.code && code.corporateId === data.corporateId && code.code !== editCode.data.code
                );

                if (isDuplicate) {
                    toast.error("This code already exists for the selected corporate.");
                    return;
                }
                setLoading(true);
                setLoadingIcon("pi pi-spinner");
                const editObj = {
                    code: data?.code,
                    fee: data?.fee,
                    corporateId: data?.corporateId,
                    title: data?.title,
                    active: data?.active,
                    currency: data?.currency,
                };
                try {
                    const response = await axiosApi.patch(`${appUrl?.baseUrl}${appUrl.editCorporateCode}?id=${editCode?.key}`, editObj);
                    if (response?.data?.statusCode === 200) {
                        setLoading(false);
                        setLoadingIcon("");
                        onHide();
                        toast.success("Corporate Code updated");
                        await dispatch(
                            getCorporateCodesList({
                                pageNumber: 0,
                                text: "",
                                perPageRecords: 50,
                            })
                        );

                    }

                } catch (error) {
                    toast.error(error);
                }
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Corporate Code successfully added");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Corporate Code successfully updated");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(editError);
            }
        }
        return () => {
            dispatch(resetAddEditCorporateCode());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    useEffect(() => {
        if (editCode !== null) {
            loadInitialValues();
        }
    }, [tableData, editCode]);

    const loadInitialValues = () => {
        formik.setFieldValue("corporateId", editCode?.data?.corporate);
        formik.setFieldValue("title", editCode?.data?.title);
        formik.setFieldValue("code", editCode?.data?.code);
        formik.setFieldValue("fee", editCode?.data?.fee);
        formik.setFieldValue("active", editCode?.data?.active);
        formik.setFieldValue("currency", editCode?.data?.currencyId);
    };
    const [searchText, setSearchText] = useState("");

    const handleInputChange = (event) => {
        const text = event;

        setSelectedAddress(event);
        // Update the search text
        setSearchText(text);

        dispatch(getGoogleAddress({ text }));
    };
    const handleOnSelect = (e) => {
        setSelectedAddress(e.title);
    };

    // const corporateList = tableData?.map((item) => {
    //     return {
    //         name: item?.data?.name,
    //         value: item?.key,
    //     };
    // });
    const handleDropdownChange = (e) => {
        formik.setFieldValue("name", e.target.value);
    };

    const [currecnyCode, setCurrencyCode] = useState();

    const getCurrencyCode = async () => {
        try {
            const response = await axiosApi.get(`${appUrl?.baseUrl}api/currency/`);
            setCurrencyCode(response?.data?.data);
        } catch (error) {
            toast.error("Can not get currecny code");
        }
    };
    useEffect(() => {
        dispatch(getCorporateList())
    }, [])

    useEffect(() => {
        getCurrencyCode();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="corporateId" formik={formik} type="dropdown" label="Corporate" placeHolder="Please select corporate" options={corporateList} optionLabel="name" optionValue="_id" />
                </div>
                <div className="p-fluid col-12 md:col-6" >
                    {editCode !== null ? <CustomInputField iden="code" formik={formik} placeHolder="xxxx-xxxxxx" type="text" disabled={true} />
                        : <CustomInputField iden="code" formik={formik} mask="****-******" placeHolder="xxxx-xxxxxx" type="text" />}
                </div>
                <div className="p-fluid col-12 md:col-6 testField">

                    <CustomInputField type="text" formik={formik} iden="title" placeHolder="Please enter title" />
                </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="fee" formik={formik} type="number" />
                </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="currency" formik={formik} type="dropdown" label="Currency Code" placeHolder="Please select currency code" options={currecnyCode} optionLabel="currencyCode" optionValue="_id" />
                </div>

                {editCode != null && (
                    <div className="p-fluid col-12 md:col-6">
                        <div className="field">
                            <CustomInputField className="col-12" iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                        </div>
                    </div>
                )}
                <div className="p-fluid col-12">
                    <div className="button_class-modal">
                        {/* {permissions?.edit && editCode !== null && ( */}
                        <Button type="submit" label={editCode === null ? "Add" : "Update"} disabled={loading ? true : false} icon={loadingIcon} iconPos="left" className="p-button-success btn__default_modal p-button-rounded" />
                        {/* )} */}
                    </div>
                    {/* <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div> */}
                </div>
            </div>
        </form>
    );
}